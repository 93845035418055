import React from "react";
import { graphql } from "gatsby";
import { PrismicRichText, SliceZone } from "@prismicio/react";
import { Container } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";
import PageBanner from "../components/elements/PageBanner";
import { constructActiveDoc } from "../utilities/helpers";
import "../styles/pages/faq.scss";

import components from "../components/slices/index";

const Faq = ({ data }) => {
  if (!data) return null;
  const { page } = data;
  // console.log(page.type);

  return (
    <div className={page.type}>
      <Seo page={page} />
      <Layout activeDocMeta={constructActiveDoc(page)}>
        <PageBanner
          title={page.data.title}
          bgImage={page.data.banner_image}
          bgImageMobile={page.data.image_mobile}
          page={page}
        />
        <Container fluid className="inner-bg faq-section">
          <Container className="max-container faq">
            <Accordion defaultActiveKey="0" className="mx-md-5">
              {page.data.accordion.map((item, index) => (
                <Accordion.Item eventKey={`${index}`} key={index}>
                  <Accordion.Header>{item.title1.text}</Accordion.Header>
                  <Accordion.Body className="border-none">
                    <PrismicRichText field={item.description.richText} />
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <div className="book-now-outline mx-auto my-5 d-none d-lg-block">
              <Anchor
                href={page.data.button_link.url}
                className="book-now-btn text-center"
                target={page.data.button_link.target}
              >
                {page.data.button_label}
              </Anchor>
            </div>
          </Container>
        </Container>
        <SliceZone components={components} slices={page.data.body} />
      </Layout>
    </div>
  );
};

export const query = graphql`
  query faqQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicFaq(lang: { eq: $lang }, id: { eq: $id }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        meta_title
        title
        banner_image {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        image_mobile {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        accordion {
          description {
            richText
          }
          title1 {
            text
          }
        }
        button_label
        button_link {
          url
        }
        body {
          ... on PrismicFaqDataBodyContactInfo {
            id
            items {
              hours
              image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              phone
              title1 {
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicFaqDataBodyBenefits {
            id
            primary {
              description {
                richText
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              title1 {
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicFaqDataBodyVideo {
            id
            slice_label
            slice_type
            primary {
              show_on_desktop
              show_on_mobile
              video_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default Faq;
